import React, { lazy, Suspense } from 'react'
import { BrowserRouter, Switch } from 'react-router-dom'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { ProtectedRoute } from '@thriveglobal/thrive-web-auth-core'
import { FullScreenLoading, useTheme } from '@thriveglobal/thrive-web-leafkit'
import { ROUTES } from './routes'

const Home = lazy(() => import('../pages/Home'))

const Router: React.FC = () => {
    const theme = useTheme()

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <BrowserRouter>
                <Suspense fallback={<FullScreenLoading />}>
                    <Switch>
                        <ProtectedRoute path={ROUTES.HOME} component={Home} />
                    </Switch>
                </Suspense>
            </BrowserRouter>
        </ThemeProvider>
    )
}

export default Router
